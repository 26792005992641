@tailwind base;
@tailwind components;
@tailwind utilities;

.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}
.almarai-regular {
  font-family: "Almarai", sans-serif;
  font-weight: 400;
  font-style: normal;
}
