.background {
  background-image: url("./custom-bg.svg"); /* Replace with your SVG file name */
  background-size: cover; /* Makes the background cover the whole area */
  background-position: center; /* Centers the background */
  background-repeat: no-repeat; /* Prevents it from repeating */
  /* height: 100vh;  */
  width: 100vw; /* Full viewport width */
}
.owner-regular {
  font-family: "Amiri", serif;
  font-weight: 400;
  font-style: normal;
}