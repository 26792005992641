.crop-container {
  position: relative;
  width: 100%;
  height: 400px;
  background: #333;
}

.controls {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn {
  @apply rounded-[10px] py-[12px] px-[12px] bg-[#432315] text-[#FFFCF7] text-[18px] font-[500];
}
.btnDelete {
  @apply rounded-[10px] py-[12px] px-[12px] bg-red-600 text-[#FFFCF7] text-[18px] font-[500];
}
.btnn2 {
  @apply rounded-[10px] py-[12px] px-[12px] border border-[#432315] bg-[#432315] text-[#FFFCF7] text-[18px] font-[500];
}
.btnn {
  @apply rounded-[10px] py-[12px] px-[12px] border border-[#432315] bg-[#432315]/0 text-[#432315] text-[18px] font-[500];
}
.btn2 {
  @apply rounded-[10px] py-[12px] w-[200px] px-[8px] bg-[#432315] text-[#FFFCF7] text-[18px] font-[500];
}
.btn9 {
  @apply rounded-[10px] py-[12px] w-[200px] px-[8px] border border-[#432315] bg-[#432315] text-[#FFFCF7] text-[18px] font-[500];
}
.btn8 {
  @apply rounded-[10px] py-[12px] w-[200px] px-[8px] bg-[#432315]/0 border border-[#432315] text-[#432315] text-[18px] font-[500];
}
.btn3 {
  @apply rounded-[10px] py-[12px] w-full max-w-[320px] px-[8px] bg-[#432315] border border-[#432315] text-[#FFFCF7] text-[18px] font-[500];
}
.btn4 {
  @apply text-center rounded-[10px] py-[12px] w-full max-w-[320px] px-[8px] border border-[#432315] text-[#432315] text-[18px] font-[500];
}
.btnLink {
  @apply rounded-[18px]  w-[130px] h-[41px] bg-[#432315] text-[#FFFCF7] text-[18px] font-[500];
}
.inpt {
  @apply outline-none my-1 w-full max-w-[5000px] bg-[#F5EFDC] p-3 rounded-[10px] border-b-[2px] border-[#432315] text-[#432315C7] text-[15px] font-[600];
}
.sh {
  box-shadow: 0px 0px 12.1px 0px #00000040;
}
.sh2 {
  box-shadow: 0px 0px 19.1px 0px #ffd35044;
}
.fadein {
  animation: comein 500ms ease-in-out;
}
.days > div:last-of-type {
  border-right: none;
}
.prods > section:last-of-type {
  border-bottom: none;
}
.error {
  @apply text-red-700;
}
@keyframes comein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

table {
  @apply rounded-[16px] my-3 w-full overflow-hidden;
}
table thead tr {
  @apply bg-[#E8DCC0];
}
table thead tr th {
  @apply py-4 border-l;
}
table thead tr th:last-child {
  @apply py-4 border-l-0;
}

table tbody tr:nth-child(odd) {
  @apply bg-white;
}
table tbody tr:nth-child(even) {
  @apply bg-[#f5f4f0];
}
table tbody tr td {
  @apply py-2 font-[500] text-[18px];
}

/* Dropdown */
.dropdown-content {
  display: none;
  position: absolute;
  padding-top: 30px;
  z-index: 1;
}

.dropdown-content div {
  background-color: #f5efdc;
  border-radius: 10px;
  min-width: 260px;
  padding: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-size: 18px;
  font-weight: 500;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.loginSh {
  box-shadow: 0px 1px 3px 1px #10182826;

  /* box-shadow: 0px 1px 2px 0px #0000004d; */
}
@media screen and (min-width: 1000px) {
  .navLinks {
    @apply text-[#3F230E] font-[800] text-[18px] flex items-center gap-3;
  }
}
@media screen and (min-width: 1200px) {
  .navLinks {
    @apply text-[#3F230E] font-[800] text-[20px] flex items-center gap-3;
  }
}
@media screen and (min-width: 1450px) {
  .navLinks {
    @apply text-[#3F230E] font-[800] text-[21px] flex items-center gap-6;
  }
}

.newCont:hover .newtextCont {
  @apply h-[280px] bg-[#e2bf78]/70;
}
.newCont:hover .newTiltle {
  @apply z-0 opacity-0;
}
.newCont:hover .textHidden {
  @apply opacity-100;
}

/* Hide scrollbars for WebKit browsers (Chrome, Safari, Edge) */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbars for Firefox */
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbars for IE, Edge */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
}

/* Optional: Forcing no scrollbars on all other browsers */
.hide-scrollbar {
  overflow: -moz-scrollbars-none; /* Deprecated for older Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbars for WebKit browsers (Chrome, Safari, Edge) */
.hide-scrollbar1::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbars for Firefox */
.hide-scrollbar1 {
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbars for IE, Edge */
.hide-scrollbar1 {
  -ms-overflow-style: none; /* IE and Edge */
}

/*  */
/*  */
/* Hide the flag and display only the country code */
.custom-phone-input .flag-dropdown {
  /* display: none; */
  /* opacity: 0; */
  border: 0px solid black;
  background-color: #432315;
  background-color: black;
}
.custom-phone-input .flag-dropdown .selected-flag {
  /* display: none; */
  opacity: 50;
  background-color: #f5efdc;
  border: #f5efdc;
}
.custom-phone-input .flag-dropdown:hover .selected-flag {
  background-color: #f5efdc; /* Keep the background color the same */
}
.custom-phone-input .flag-dropdown:focus .selected-flag {
  background-color: #f5efdc;

  /* Keep the background color the same */
}
.custom-phone-input .flag-dropdown .selected-flag {
  /* display: none; */
  /* opacity: 0; */
  background-color: #f5efdc;
  /* border:  #F5EFDC; */
  /* border: 0px solid #F5EFDC; */
  /* background-color: black; */
}

.custom-phone-input .flag-dropdown .country-list {
  padding: 0;
  opacity: 100;
  border: none;
}

/* Style the input field */
.custom-phone-input-input {
  width: 100%;
  max-width: 340px;
  background-color: #f5efdc; /* Match background color */
  padding: 12px;
  border-radius: 10px;
  border-bottom: 2px solid #432315;
  color: #432315c7;
  font-size: 15px;
  font-weight: 600;
}

/* Style the dropdown list */
.custom-phone-dropdown {
  background-color: #f5efdc; /* Same background color as input */
  border-radius: 10px;
  border: 0px solid #432315;
}
.custom-phone-dropdown .country-list {
  background-color: #f5efdc; /* Same background color as input */
  border-radius: 10px;
  border: 0px solid #432315;
}

/* Style each dropdown item */
.custom-phone-dropdown .country {
  padding: 10px;
  color: #432315c7;
  font-size: 15px;
  font-weight: 600;
  background-color: #f5efdc;
}

/* Hover effect for dropdown items */
.custom-phone-dropdown .country:hover {
  background-color: #f5efdc;
}
.w-deta {
  @apply w-full;
}

@media screen and (min-width: 768px) {
  .w-deta {
    @apply w-full max-w-[450px];
  }
}
@media screen and (min-width: 870px) {
  .w-deta {
    @apply w-full max-w-[550px];
  }
}
@media screen and (min-width: 1000px) {
  .w-deta {
    @apply w-full max-w-[680px];
  }
}
@media screen and (min-width: 1024px) {
  .wtext {
    @apply max-w-[280px];
  }
}
@media screen and (min-width: 1100px) {
  .w-deta {
    @apply w-full max-w-[780px];
  }
  .wtext {
    @apply max-w-[360px];
  }
}
@media screen and (min-width: 1200px) {
  .w-deta {
    @apply w-full max-w-[880px];
  }
  .wtext {
    @apply max-w-[460px];
  }
}
@media screen and (min-width: 1300px) {
  .w-deta {
    @apply w-full max-w-[980px];
  }
  .wtext {
    @apply max-w-[560px];
  }
}
@media screen and (min-width: 1400px) {
  .w-deta {
    @apply w-full max-w-[1080px];
  }
  .wtext {
    @apply max-w-[660px];
  }
}
@media screen and (min-width: 1500px) {
  .w-deta {
    @apply w-full max-w-[1180px];
  }
  .wtext {
    @apply max-w-[760px];
  }
}
@media screen and (min-width: 1600px) {
  .w-deta {
    @apply w-full max-w-[1280px];
  }
  .wtext {
    @apply max-w-[860px];
  }
}
@media screen and (min-width: 1700px) {
  .w-deta {
    @apply w-full max-w-[1380px];
  }
}
@media screen and (min-width: 1800px) {
  .w-deta {
    @apply w-full max-w-[1480px];
  }
}
@media screen and (min-width: 1900px) {
  .w-deta {
    @apply w-full max-w-[1580px];
  }
}

/* Hide the default checkbox */
.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #432315;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

/* Style the checkbox when checked */
.custom-checkbox:checked {
  background-color: green; /* Green background */
  border: 2px solid green;
}

/* Add the white checkmark */
.custom-checkbox:checked::after {
  content: "\2713"; /* Unicode for checkmark */
  font-size: 16px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Chrome, Safari, Edge, Opera */
.sumBtn::-webkit-outer-spin-button,
.sumBtn::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.sumBtn {
  -moz-appearance: textfield;
}
.shcover {
  box-shadow: 5px red;
}

/* Custom styles for header sizes using Tailwind classes */
.styled-content {
  @apply break-words;
}
.styled-content h1 {
  @apply text-4xl font-bold;
}

.styled-content h2 {
  @apply text-3xl font-semibold;
}

.styled-content h3 {
  @apply text-2xl font-medium;
}

.styled-content h4 {
  @apply text-xl font-normal;
}

.styled-content h5 {
  @apply text-lg font-light;
}

.styled-content h6 {
  @apply text-base font-thin;
}

/* Keep the list and other custom styles for RTL */
.rtl .ql-editor {
  direction: rtl;
  text-align: right;
}

.styled-content ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-right: 20px;
}

.styled-content ol {
  list-style-type: decimal;
  margin-left: 20px;
  margin-right: 20px;
}
